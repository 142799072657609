export const createUrl = (
  isSecure: boolean,
  url: string,
  port: number,
  path = ''
): string => {
  const httpType: string = checkHttpType(isSecure)
  const urlResult = `${httpType}${url}:${port}/${path}`
  return urlResult
}

const checkHttpType = (isSecure: boolean): string =>
  isSecure ? 'https://' : 'http://'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
Vue.config.productionTip = false
// Vue.prototype.$http = axios.create({
//   baseURL: 'http://localhost:8041'
// })

// Vue.use(VueTour);
Vue.filter('truncated', (val: string, length: number, suffix: string) => {
  if (val && val.length > length) return val.substring(0, length) + suffix
  else return val
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import { apiService } from '../../services/api.service'
const GET_DOCKERS = 'getDockers'

const state = {
  dockers: [],
  loadingDockers: true
}

const getters = {
  dockers: state => state.dockers,
  loadingDockers (state) {
    return state.loadingDockers
  }
}

const actions = {
  [GET_DOCKERS] (state, data) {
    state.commit(GET_DOCKERS, data)
  }
}

const mutations = {
  async [GET_DOCKERS] (state) {
    await apiService
      .get('admin/docker-list')
      .then((res) => {
        state.dockers = res.data.dockers
      })
      .finally(() => {
        state.loadingDockers = false
      })
  }

}

export default {
  state,
  actions,
  mutations,
  getters
}

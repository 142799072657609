
import Vue from 'vue'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'NotificationSideMenu',
  computed: {
    ...mapGetters([
      'changedClientParameters',
      'nonNotifiedStreamEngineVersionIds',
      'nonNotifiedClientVersionIds'
    ]),
    isChangeExist () {
      return (
        this.nonNotifiedClientVersionIds.length > 0 ||
        this.nonNotifiedStreamEngineVersionIds.length > 0 ||
        Object.keys(this.changedClientParameters).length > 0
      )
    }
  },
  methods: {
    notifyClients () {
      Swal.fire({
        title: 'Are you sure?',
        text: 'All Clients which are connected to Cordatus will be notified about the changes. This process cannot be undone!',
        icon: 'question',
        showCancelButton: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const notificationConfig = await this.$store.dispatch('notifyClients', { notifyAll: true })
            notificationConfig.status = 'Notifying'
            await this.$store.dispatch('updateNotificationStatus', { body: notificationConfig })
            this.$store.dispatch('removeAllNonNotifiedVersions')
            this.$store.dispatch('clearNonNotifiedParameters')
            Swal.fire('Success!', 'Started notifying process.', 'success')
          } catch (error) {
            console.log('Error while notifying clients: ', error.message)
            Swal.fire(
              'Warning!',
              'Notifying process cannot be started.',
              'warning'
            )
          }
        }
      })
    }
  }
})

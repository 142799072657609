import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module'
import docker from './docker.module'
import token from './token.module'
import version from './versions.module'
import parameters from './parameters.module'
import updateNotification from './update-notification.module'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    auth,
    docker,
    token,
    version,
    parameters,
    updateNotification
  }
})

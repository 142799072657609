
import { apiService } from '../../services/api.service'
import Vue from 'vue'

export default Vue.extend({
  name: 'Home',
  data: () => ({
    show: false,
    reveal: false,
    licences: [],
    loading: false,
    alldata: [
      {
        name: 'Users',
        status: 'Active',
        total: 0,
        icon: 'mdi-account-multiple'
      },
      {
        name: 'Licences',
        status: 'Active',
        total: 0,
        icon: 'mdi-card-account-details-outline'
      },
      {
        name: 'Devices',
        status: 'Active',
        total: 0,
        icon: 'mdi-cellphone-link'
      },
      {
        name: 'Dockers',
        status: 'Active',
        total: 0,
        icon: 'mdi-docker'
      }
    ]
  }),
  created () {
    this.allLicences()
  },
  methods: {
    async allLicences () {
      await apiService
        .get('users/')
        .then((res) => {
          this.alldata[0].total = res.data.length
        })
        .finally(() => (this.loading = false))

      await apiService
        .get('licences/all')
        .then((res) => {
          this.alldata[1].total = res.data.length
        })
        .finally(() => (this.loading = false))

      await apiService
        .get('devices/all')
        .then((res) => {
          this.alldata[2].total = res.data.length
        })
        .finally(() => (this.loading = false))

      await apiService
        .get('dockers/all')
        .then((res) => {
          this.alldata[3].total = res.data.length
        })
        .finally(() => (this.loading = false))
    }
  }
})

import axios from 'axios'
import jwtService from '../src/service/jwt.service'
import { createUrl } from '@/utils/helpers'
import environment from '../environment'

export const apiService = axios.create({
  baseURL: createUrl(
    environment.backendSecure,
    environment.backendUrl,
    environment.backendPort
  ),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${jwtService.getToken()}`,
    'Access-Control-Allow-Origin': '*'
  }
})

export const apiServiceApi = axios.create({
  baseURL: createUrl(
    environment.apiSecure,
    environment.apiUrl,
    environment.apiPort
  ),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${jwtService.getToken()}`,
    'Access-Control-Allow-Origin': '*'
  }
})

export const apiServiceAgent = axios.create({
  baseURL: createUrl(
    environment.agentSecure,
    environment.agentUrl,
    environment.agentPort
  ),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${jwtService.getToken()}`,
    'Access-Control-Allow-Origin': '*'
  }
})

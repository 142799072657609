import Vue from 'vue'

export const ADD_USAGE = 'addUsage'
export const SET_CHANGED_PARAMETERS = 'setChangedParameters'
export const CLEAR_NON_NOTIFIED_PARAMETERS = 'clearNonNotifiedParameters'
// Variables

export default {
  state: {
    changedClientParameters: {}
  },
  getters: {
    changedClientParameters: (state) => state.changedClientParameters
  },
  actions: {
    [SET_CHANGED_PARAMETERS] (state, payload) {
      state.commit(SET_CHANGED_PARAMETERS, payload)
    },
    [CLEAR_NON_NOTIFIED_PARAMETERS] (state) {
      state.commit(CLEAR_NON_NOTIFIED_PARAMETERS)
    }
  },
  mutations: {
    [SET_CHANGED_PARAMETERS] (state, payload) {
      for (const key in payload) {
        Vue.set(state.changedClientParameters, key, payload[key])
      }
    },
    [CLEAR_NON_NOTIFIED_PARAMETERS] (state) {
      state.changedClientParameters = {}
    }
  }
}

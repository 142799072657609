// action types
const SET_AUTHENTICATED = 'setAuthenticated'

const state = {
  user: [],
  isAuthenticated: false
}

const getters = {
  isAuthenticated (state) {
    return state.isAuthenticated
  },
  userInfo (state) {
    return state.user
  }
}

const actions = {
  [SET_AUTHENTICATED] (state, data) {
    state.commit(SET_AUTHENTICATED, data)
  },
  setUser ({ commit }) {
    commit('setUser')
  }
}

const mutations = {
  [SET_AUTHENTICATED] (state, data) {
    state.isAuthenticated = data
  },
  setUser (state, user) {
    state.user = user
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}

import getEnv from './src/utils/env'
interface Environment {
  nodeEnv: string;
  backendUrl: string;
  backendPort: number;
  backendSecure: boolean;
  apiUrl: string;
  apiPort: number;
  apiSecure: boolean;
  frontendUrl: string;
  frontendPort: number;
  frontendSecure: boolean;
  signalServerUrl: string;
  signalServerPort: number;
  signalServerSecure: boolean;
  tunnelServerUrl: string;
  tunnelServerPort: number;
  tunnelServerSecure: boolean;
  harborUrl: string;
  agentUrl: string;
  agentPort: number;
  agentSecure: boolean;
}

const environment: Environment = {
  nodeEnv: getEnv('NODE_ENV'),
  backendUrl: getEnv('VUE_APP_BACKEND_URL'),
  backendPort: parseInt(getEnv('VUE_APP_BACKEND_PORT')),
  backendSecure: getEnv('VUE_APP_BACKEND_SECURE') === 'true',
  apiUrl: getEnv('VUE_APP_API_URL'),
  apiPort: parseInt(getEnv('VUE_APP_API_PORT')),
  apiSecure: getEnv('VUE_APP_API_SECURE') === 'true',
  frontendUrl: getEnv('VUE_APP_FRONTEND_URL'),
  frontendPort: parseInt(getEnv('VUE_APP_FRONTEND_PORT')),
  frontendSecure: getEnv('VUE_APP_FRONTEND_SECURE') === 'true',
  signalServerUrl: getEnv('VUE_APP_SIGNAL_SERVER_URL'),
  signalServerPort: parseInt(getEnv('VUE_APP_SIGNAL_SERVER_PORT')),
  signalServerSecure: getEnv('VUE_APP_SIGNAL_SECURE') === 'true',
  tunnelServerUrl: getEnv('VUE_APP_TUNNEL_SERVER_URL'),
  tunnelServerPort: parseInt(getEnv('VUE_APP_TUNNEL_SERVER_PORT')),
  tunnelServerSecure: getEnv('VUE_APP_TUNNEL_SERVER_SECURE') === 'true',
  harborUrl: getEnv('VUE_APP_HARBOR_URL'),
  agentUrl: getEnv('VUE_APP_AGENT_URL'),
  agentPort: parseInt(getEnv('VUE_APP_AGENT_PORT')),
  agentSecure: getEnv('VUE_APP_AGENT_SECURE') === 'true'
}

export default environment

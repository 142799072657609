import VueCookies from 'vue-cookies'
import Vue from 'vue'
import environment from '../../environment'

const ID_TOKEN_KEY = 'C_ID'

Vue.use(VueCookies)
Vue.$cookies.config('1d', '', '', environment.nodeEnv !== 'development')
export const getToken = () => {
  return Vue.$cookies.get(ID_TOKEN_KEY)
}

export const destroyToken = () => {
  if (environment.nodeEnv === 'development') {
    Vue.$cookies.remove(ID_TOKEN_KEY)
  } else {
    Vue.$cookies.remove(ID_TOKEN_KEY, null, 'cordatus.ai')
  }
}

export default { getToken, destroyToken }

import Swal from 'sweetalert2'
import { apiService } from '../../services/api.service'
const UPDATE_TOKENS = 'updateTokens'
const GET_TOKENS = 'getTokens'

const state = {
  tokens: {},
  loadingUpdateTokens: false
}

const getters = {
  loadingUpdateTokens (state) {
    return state.loadingUpdateTokens
  }
}

const actions = {
  [GET_TOKENS] (state, data) {
    state.commit(GET_TOKENS, data)
  },
  [UPDATE_TOKENS] (state, data) {
    state.commit(UPDATE_TOKENS, data)
  }
}

const mutations = {
  async [GET_TOKENS] (state) {
    await apiService
      .get('admin-get-tokens')
      .then((res) => {
        state.tokens = res.data.tokens
      })
      .finally(() => {
        state.loadingUpdateTokens = false
      })
  },

  async [UPDATE_TOKENS] (state, payload) {
    state.loadingUpdateTokens = true
    await apiService
      .put('admin-update-tokens-expires', payload)
      .then((res) => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: res.data.message
        })
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '',
          text: err.response.data.message
        })
      })
      .finally(() => {
        state.loadingUpdateTokens = false
      })
  }

}

export default {
  state,
  actions,
  mutations,
  getters
}
